/*!* You can add global styles to this file, and also import other style files *!*/

/*@import '../src/assets/css/style.css';*/

/*.ant-message {*/
/*  top: 85px !important;*/
/*}*/

/*.ant-pagination-item-link .anticon svg {*/
/*  vertical-align: unset;*/
/*}*/

/*.anticon {*/
/*  display: inline-block;*/
/*  color: inherit;*/
/*  font-style: normal;*/
/*  line-height: 0;*/
/*  text-align: center;*/
/*  text-transform: none;*/
/*  vertical-align: -.125em;*/
/*  text-rendering: optimizeLegibility;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*.sidebar-main .iq-sidebar-menu .iq-menu li ul {*/
/*  padding-left: 25px;*/
/*}*/

/*!* .container {*/
/*    -webkit-transition: all 0.2s ease-in;*/
/*    -moz-transition: all 0.2s ease-in;*/
/*    -ms-transition: all 0.2s ease-in;*/
/*    -o-transition: all 0.2s ease-in;*/
/*    transition: all 0.2s ease-in;*/
/*} *!*/

/*.ant-select {*/
/*  width: 100% ! important;*/
/*}*/

/*.stepwizard {*/
/*  margin: 0px auto 40px;*/
/*}*/
/*.stepwizard-row  .wizard-step {*/
/*  display: inline-block;*/
/*  float: none;*/
/*  width: auto;*/
/*  position: static;*/
/*  font-weight: 400;*/
/*  text-align: center;*/
/*}*/

/*.stepwizard-row .wizard-step a.btn {*/
/*  display: inline-block;*/
/*  font: normal normal normal 16px/19px Montserrat;*/
/*  width: 177px;*/
/*  height: 177px;*/
/*  border-radius: 14px;*/
/*  margin: 30px;*/
/*  padding: 0;*/
/*}*/

/*.question-box {*/
/*  background: #ffffff;*/
/*  color: #777D74;*/
/*  font-size: 18px;*/
/*  font-weight: 400;*/
/*  display: block;*/
/*  padding: 15px;*/
/*  margin: 20px 20px;*/
/*  border-radius: 5px;*/
/*  -webkit-border-radius: 5px;*/
/*  text-decoration: none;*/
/*  !* border: 1px solid #f4f3ff; *!*/
/*  border: 1px solid #7c7c82;*/
/*}*/
/*.add-question,*/
/*.add-btn {*/
/*  font-size: 36px;*/
/*  color: #26247B!important;*/
/*}*/
/*.txt-heading {*/
/*  font-size: 18px;*/
/*  font-weight: 600;*/
/*  color: #000000;*/
/*}*/
/*.activity-tab-heading {*/
/*  text-align: center;*/
/*  font: normal normal 600 24px/29px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  margin: 10px auto;*/
/*}*/
/*.activity-tab-sub-heading-2,*/
/*.activity-tab-sub-heading-1{*/
/*  text-align: center;*/
/*  font: normal normal 600 20px/24px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  margin: 15px auto;*/
/*}*/
/*.activity-tab-sub-heading-2{*/
/*  font: normal normal 600 18px/22px Montserrat;*/
/*  margin: 0px auto 40px;*/
/*}*/
/*td>a>i {*/
/*  font-size: 18px;*/
/*}*/
/*.heading-text {*/
/*  font-size: 22px;*/
/*  color: #000;*/
/*  text-align: center;*/
/*  font-weight: 600;*/
/*  padding: 0px 0 20px;*/
/*  margin: 20px 0;*/
/*}*/

/*.content-page.bg-white {*/
/*  background-color: #ffffff;*/
/*}*/
/*.search-btn {*/
/*  font-size: 20px;*/
/*}*/
/*.avatar-small {*/
/*  width: 40px;*/
/*  height: 40px;*/
/*  border-radius: 50%;*/
/*}*/
/*.ant-list-header {*/
/*  font-size: 18px;*/
/*  font-weight: 600;*/
/*}*/

/*.ant-list-item-meta-title {*/
/*  font-size: 16px;*/
/*  margin-top: 4px;*/
/*}*/
/*.iq-sidebar-menu .custIcon{*/
/*  display: inline-block;*/
/*  margin-right: 10px;*/
/*  vertical-align: middle;*/
/*  width: 20px;*/
/*}*/
/*.plusIcon{*/
/*  color: #26247B;*/
/*}*/
/*.delete-icon{*/
/*  color: #3F414D;*/
/*  font-size: 24px;*/
/*}*/
/*.ant-btn-primary {*/
/*  color: #fff;*/
/*  background: #F26522;*/
/*  border-color: #F26522;*/
/*}*/
/*.ant-btn:hover, .ant-btn:focus {*/
/*  color: #F26522;*/
/*  background: #fff;*/
/*  border-color: #F26522;*/
/*}*/
/*.ant-btn-primary:hover, .ant-btn-primary:focus {*/
/*  color: #fff;*/
/*  background: #F26522;*/
/*  border-color: #F26522;*/
/*}*/
/*.grayIcon{*/
/*  color:#3F414D !important;*/
/*}*/
/*.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover {*/
/*  color: #F26522;*/
/*}*/

/*.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {*/
/*  border-color: #F26522;*/
/*  box-shadow: 0 0 0 2px #f2652233;*/
/*}*/
/*.ant-select:not(.ant-select-disabled):hover .ant-select-selector {*/
/*  border-color: #F26522;*/
/*  border-right-width: 1px !important;*/
/*}*/
/*.ant-picker {*/
/*  width: 100%;*/
/*}*/
/*.setup-activity-content {*/
/*  width: 80%;*/
/*  margin: 60px auto;*/
/*}*/

/*.ant-statistic .ant-statistic-title{*/
/*  color: #ffffff !important;*/
/*  font: normal normal 600 11px/14px Montserrat;*/
/*  text-align: center;*/
/*}*/
/*.ant-statistic{*/
/*  width: 90px;*/
/*  text-align: center;*/
/*}*/
/*.ant-statistic .ant-statistic-content-value*/
/*{*/
/*  font: normal normal 600 20px/24px Montserrat;*/
/*  color: #ffffff !important;*/
/*  text-align: center;*/
/*  display: block;*/
/*}*/
/*.content-page .ant-progress-outer {*/
/*  line-height: 0;*/
/*  font-size: 0;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*.ant-progress-outer .ant-progress-inner {*/
/*  border-radius: 0;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  background-color: #3f414d;*/
/*}*/
/*.ant-progress {*/
/*  line-height: 0;*/
/*  font-size: 0;*/
/*}*/

/*.timer {*/
/*  width: 110px;*/
/*  height: 110px;*/
/*  background-color: #26247B;*/
/*  padding: 10px;*/
/*  border-radius: 50%;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  position: absolute;*/
/*  top: 90px;*/
/*  right: 130px;*/
/*}*/

/*!* .iq-card-body nz-table {*/
/*    width: 100%;*/
/*    overflow-y: auto;*/
/*} *!*/

/*.e-rte-content{*/
/*  margin-top: 42px !important;*/
/*}*/

/*.ant-rate {*/
/*  color: #F26522;*/
/*  font-size: 30px!important;*/
/*  line-height: 30px!important;*/
/*  font-weight: 900!important;*/
/*}*/
/*.ant-checkbox-inner {*/
/*  width: 24px;*/
/*  height: 24px;*/
/*}*/
/*.ant-checkbox-checked .ant-checkbox-inner {*/
/*  background-color: #26247B;*/
/*  border-color: #26247B;*/
/*}*/
/*.ant-checkbox-wrapper:hover .ant-checkbox-inner,*/
/*.ant-checkbox:hover .ant-checkbox-inner,*/
/*.ant-checkbox-input:focus + .ant-checkbox-inner {*/
/*  border-color: #26247B;*/
/*}*/
/*.ant-checkbox-wrapper {*/
/*  padding-top: 10px;*/
/*}*/
/*.add-choice-btn{*/
/*  background: #F4F3FF 0% 0% no-repeat padding-box;*/
/*  border: 1px solid #26247B;*/
/*  border-radius: 5px;*/
/*}*/

/*.stepwizard-row .wizard-step i {*/
/*  height: 63px;*/
/*  width: 63px;*/
/*  line-height: 63px;*/
/*  font-size: 24px;*/
/*  margin: 30px auto 0;*/
/*}*/
/*.stepwizard-row .wizard-step a.btn span {*/
/*  display: block;*/
/*  text-align: center;*/
/*  font: normal normal normal 16px/19px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  margin: 30px auto 15px;*/
/*}*/
/*.ant-form label,*/
/*.ant-input-group > span,*/
/*.ant-form-item-label > label {*/
/*  font: normal normal normal 16px/19px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*}*/
/*.activity-type-heading {*/
/*  text-align: left;*/
/*  font: normal normal 600 24px/29px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  padding: 30px 0 15px;*/
/*}*/
/*.activity-title-heading {*/
/*  text-align: left;*/
/*  font: normal normal bold 20px/24px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  padding: 15px 0 12px;*/
/*}*/
/*.activity_description-text {*/
/*  text-align: left;*/
/*  font: normal normal normal 16px/28px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  padding: 12px 0 24px;*/
/*}*/
/*.activity-card-individual {*/
/*  background: #FFFCF6 0% 0% no-repeat padding-box;*/
/*  box-shadow: 0px 3px 5px #00000014;*/
/*  border-radius: 7px;*/
/*  padding: 12px;*/
/*}*/
/*.activity-card-individual.orange{*/
/*  border: 1px solid #F6B22A;*/
/*}*/
/*.activity-card-individual.green{*/
/*  border: 1px solid #6AC445;*/
/*  !* border-top: 7px solid #6AC445; *!*/
/*}*/
/*.activity-card-individual.grey{*/
/*  border: 1px solid #3f414d;*/
/*  !* border-top: 7px solid #3f414d; *!*/
/*}*/

/*.activity-card-individual .activity-card-left-icon{*/
/*  font: normal normal normal 22px/25px Montserrat;*/
/*}*/

/*.activity-card-individual .activity-card-title {*/
/*  font: normal normal normal 12px/15px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  !* margin: 5px auto; *!*/
/*}*/

/*.activity-card-individual .activity-card-date-range{*/
/*  font: normal normal normal 10px/13px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  text-align: center;*/
/*}*/

/*.activity-card-individual .activity-card-time-score{*/
/*  font: normal normal normal 12px/15px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  margin: 36px auto;*/
/*}*/
/*.activity-card-individual .activity-card-review-status{*/
/*  margin: 15px auto 0;*/
/*  font: normal normal 600 10px/25px Montserrat;*/
/*  color: #3F414D;*/
/*  text-transform: capitalize;*/
/*}*/
/*.activity-card-individual .activity-card-ques-attempt{*/
/*  font: normal normal 600 12px/25px Montserrat;*/
/*  margin-top: 12px;*/
/*  text-align: center;*/
/*  color: #3F414D;*/
/*}*/
/*.activity-card-individual .activity-card-member-count {*/
/*  font: normal normal normal 12px/15px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*  margin: 20px auto 15px;*/
/*}*/
/*.activity-card-btn{*/
/*  display: inline-block;*/
/*  margin: 5px;*/
/*}*/
/*.activity-card-btn div{*/
/*  text-align: center;*/
/*  font: normal normal normal 10px/25px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*}*/
/*.activity-card-btn button{*/
/*  text-transform: capitalize;*/
/*  font: normal normal normal 10px/13px Montserrat;*/
/*}*/
/*.activity-card-text {*/
/*  margin: 37px auto;*/
/*}*/

/*.activity-card-squence-no {*/
/*  margin: 15px auto 0;*/
/*  font: normal normal 600 10px/25px Montserrat;*/
/*  color: #3F414D;*/
/*  text-transform: capitalize;*/
/*  position: absolute;*/
/*  bottom: 20px;*/
/*  right: 30px;*/
/*}*/
/*.help-txt-sec {*/
/*  display: flex;*/
/*  line-height: 1.78;*/
/*}*/
/*.back-btn i{*/
/*  font: normal normal 900 26px/26px Montserrat;*/
/*  color: #26247B!important;*/
/*}*/

/*.back-btn:hover, .back-btn:focus {*/
/*  border-color: #fff;*/
/*}*/

/*.micContainer {*/
/*  font: normal normal normal 16px/19px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*}*/

/*.btnMic {*/
/*  !* cursor: pointer; *!*/
/*  width: 74px;*/
/*  height: 74px;*/
/*  !* padding: 21px; *!*/
/*  border-radius: 50%;*/
/*  !* background-color: #F26522; *!*/
/*  !* display: flex;*/
/*  align-items: center;*/
/*  align-content: center; *!*/
/*}*/

/*.btnMic.recording:focus {*/
/*  animation-name: pulse;*/
/*  animation-duration: 1.5s;*/
/*  animation-iteration-count: infinite;*/
/*  animation-timing-function: linear;*/
/*}*/

/*@keyframes pulse {*/
/*  0% {*/
/*    box-shadow: 0px 0px 5px 0px rgba(255, 86, 2, 0.3);*/
/*  }*/
/*  65% {*/
/*    box-shadow: 0px 0px 5px 13px rgba(248, 85, 3, 0.3);*/
/*  }*/
/*  90% {*/
/*    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);*/
/*  }*/
/*}*/

/*.btnMic i {*/
/*  font-size: 30px;*/
/*  color: #ffffff;*/
/*}*/

/*.activity-mic-container{*/
/*  justify-content: space-between;*/
/*  background: #FFFFFF 0% 0% no-repeat padding-box;*/
/*  border: 1px solid #E9E9E9;*/
/*  border-radius: 7px;*/
/*  padding: 10px;*/
/*  text-align: left;*/
/*  font: normal normal normal 14px/18px Montserrat;*/
/*  letter-spacing: 0px;*/
/*  color: #3F414D;*/
/*}*/

/*.activity-mic-container .btnMic{*/
/*  width: 36px;*/
/*  height: 36px;*/
/*  border-radius: 50%;*/
/*}*/
/*.activity-mic-container .btnMic i {*/
/*  font-size: 20px;*/
/*}*/
/*.ant-list-item-meta-avatar .ant-avatar-image {*/
/*  background: #ccc;*/
/*}*/
/*.audio-card {*/
/*  padding: 12px;*/
/*  margin: 17px 0;*/
/*  border: 1px solid #E9E9E9;*/
/*  border-radius: 7px;*/
/*}*/

/*audio {*/
/*  height: 30px!important;*/
/*}*/

/*.ck-editor__editable_inline {*/
/*  min-height: 150px;*/
/*}*/
/*.navbar-list li .iq-sub-dropdown .iq-sub-card{*/
/*  padding: 10px;*/
/*}*/
/*.review-form.ant-form .ant-form-item-label > label{*/
/*  font: normal normal normal 12px/15px Montserrat;*/
/*  letter-spacing: 0px !important;*/
/*  color: #3F414D !important;*/
/*}*/
/*.review-form.ant-form .ant-switch{*/
/*  vertical-align: top;*/
/*}*/
/*.disabled{*/
/*  pointer-events: none;*/
/*  !* cursor: default; *!*/
/*  cursor: not-allowed;*/
/*  text-decoration: none;*/
/*}*/
/*.disabled i{*/
/*  color: #CCCCCC;*/
/*}*/

/*.ql-editor{*/
/*  min-height: 150px;*/
/*}*/
/*.ngx-quill-view-html .ql-editor{*/
/*  padding: 0;*/
/*}*/
/*.question-checkbox input.ant-checkbox-input {*/
/*  width: 0;*/
/*  height: 0;*/
/*}*/

.html2canvas-container { width: 30000px !important; height: 30000px !important; }
